import React, { Component } from 'react'
import { connect } from 'react-redux'
import { href } from '@licnz/js-utils'

import { enqueueError, enqueueInfo } from 'lib/components/GlobalNotifications'
import { fetchSupplyOrder, clearSupplyOrder, manuallyCancelSupplyOrder } from 'actions/supplyOrderActions'
import {
  fetchSupplyOrderErrors,
  clearSupplyOrderErrors,
} from 'actions/supplyOrderErrorsActions'
import { isAdmin } from 'selectors/userInfoSelectors'
import { SUPPLY_ORDER_STATE_MAPPINGS } from 'constants/stateMappings'

import ButtonToolbar from 'lib/components/ButtonToolbar'
import CancelOrderModal from 'lib/components/CancelOrderModal'
import PageHeading from 'lib/components/headings/PageHeading'
import PageSection from 'lib/components/layout/PageSection'
import SupplyOrderDetail from './SupplyOrderDetail'

import styles from './styles.scss'

class SupplyOrder extends Component {
  constructor(props) {
    super(props)
    this.handleBack = this.handleBack.bind(this)
    this.handleManuallyCancelSupplyOrder = this.handleManuallyCancelSupplyOrder.bind(this)
  }

  handleBack(e) {
    e.preventDefault()
    this.props.history.goBack()
  }

  componentDidMount() {
    let {
      fetchSupplyOrder,
      match: {
        params: { id },
      },
    } = this.props
    fetchSupplyOrder(id)
  }

  componentWillUnmount() {
    let { clearSupplyOrder, clearSupplyOrderErrors } = this.props
    clearSupplyOrder()
    clearSupplyOrderErrors()
  }

  componentDidUpdate(prevProps) {
    let { supplyOrder, fetchSupplyOrderErrors } = this.props
    if (prevProps.supplyOrder != supplyOrder) {
      let supplyOrderLink = href({ links: supplyOrder.links, rel: 'self' })
      fetchSupplyOrderErrors(decodeURIComponent(supplyOrderLink))
    }
  }

  handleManuallyCancelSupplyOrder() {
    let { manuallyCancelSupplyOrder, enqueueError, enqueueInfo, supplyOrder } = this.props
    let manualSupplyOrderCancellationLink = supplyOrder
      ? href({ links: supplyOrder.links, rel: 'manual_supply_order_cancellation' })
      : null

    return manuallyCancelSupplyOrder(manualSupplyOrderCancellationLink)
      .then(() => {
        enqueueInfo({ message: 'The supply order has been successfully manually cancelled.' })
      })
      .catch(() => enqueueError({ message: 'We were unable to cancel this supply order' }))
  }

  renderButtonToolbar() {
    let { supplyOrder } = this.props
    let manualCancellationLink = supplyOrder
      ? href({ links: supplyOrder.links, rel: 'manual_supply_order_cancellation' })
      : null

    return supplyOrder && manualCancellationLink ? (
      <div className={styles.buttonToolbar}>
        <ButtonToolbar>{this.renderManuallyCancelSupplyOrderModal()}</ButtonToolbar>
      </div>
    ) : null
  }

  renderManuallyCancelSupplyOrderModal() {
    let { isAdmin, supplyOrder } = this.props
    return (
      supplyOrder && isAdmin && (supplyOrder.state !== SUPPLY_ORDER_STATE_MAPPINGS.CANCELLED) && (supplyOrder.state !== SUPPLY_ORDER_STATE_MAPPINGS.COMPLETED) && (
      <CancelOrderModal
        buttonClassName='buttonDelete'
        buttonText='Manually cancel supply order'
        handleCancel={this.handleManuallyCancelSupplyOrder}
        order={supplyOrder}
        modalTextLines={[
          'Are you sure you want to cancel this supply order?',
          'All items will be removed, and you will need to start a new order.',
          'Remember to check the corresponding sales order products and purchase order(s) in SAP; these may \
need to be cancelled manually, as a message will only be sent to SAP if the whole order is cancelled.',
          `(${supplyOrder.ordered_products.length} items in supply order)`
        ]}
      />
      )
    )
  }

  render() {
    let { supplyOrder, supplyOrderErrors } = this.props
    return (
      <PageSection>
        <PageHeading
          heading='Supply order detail'
          onAction={this.handleBack}
          actionText='Back'
        />
        {global.FEATURE_FLAGS.MANUAL_CANCELLATION &&
          this.renderButtonToolbar()
        }
        {supplyOrder ? (
          <SupplyOrderDetail
            supplyOrder={supplyOrder}
            supplyOrderErrors={supplyOrderErrors}
          />
        ) : null}
      </PageSection>
    )
  }
}

const mapDispatchToProps = {
  clearSupplyOrder,
  enqueueError,
  enqueueInfo,
  fetchSupplyOrder,
  clearSupplyOrderErrors,
  fetchSupplyOrderErrors,
  manuallyCancelSupplyOrder,
}

export { SupplyOrder }
export default connect(state => {
  return {
    isAdmin: isAdmin(state),
    supplyOrder: state.supplyOrder.data,
    supplyOrderErrors: state.supplyOrderErrors.data,
  }
}, mapDispatchToProps)(SupplyOrder)
